import { Tabs, TabsProps } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import allocationStore from '../../../stores/allocationStore';
import { CustomerModel } from '../../../types/CustomerModel';
import { parseFormattedDate } from '../../../utils/dateFunctions';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import AllocationInformation from './AllocationInformation';
import AlocationDetails from './AlocationDetails';
import lockersStore from '../../../stores/lockersStore';
import { editValidationSchema, validationSchema } from './schema';
import Permission from '../../molecules/Permission';
import { PermissionAllocation } from '../../../permissions/PermissionAllocation';
import ModalComponent from '../../organisms/ModalComponent';
import { allocationMethodLabels } from '../../../types/AllocationModel';

const breadCrumb = [
    {
        title: <a href="/alocacao">Listagem de Alocações</a>,
    },
    {
        title: <p>Cadastro de Alocação</p>,
    },
]

const CreateAllocation: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { allocation, loading } = allocationStore;
    const navigate = useNavigate();
    const [activeTabKey, setActiveTabKey] = useState('1');
    const [selectedPorts, setSelectedPorts] = useState<any[]>([]);
    const [showRetrieveWarningModal, setShowRetrieveWarningModal] = useState(false)

    useEffect(() => {
        const getInformation = async () => {
            if (uuid) {
                const response = await allocationStore.getAllocationByUuid(uuid);
                document.title = `Alocação - ${allocationStore.allocation?.code}`;
                await lockersStore.getLockerByUuid(response.locker.uuid);

                if (response.containerAllocations.length > 0) {
                    setSelectedPorts(response.containerAllocations);
                }
            }else {
                document.title = `Cadastro de Alocação`
            }
        };

        const getStatus = async () => {
            if (uuid) {
                await allocationStore.getListStatus();
            }
        };

        getStatus();
        getInformation();

        return () => {
            allocationStore.reset();
            lockersStore.reset();
        };
    }, [uuid]);

    const initialValues: any = {
        code: allocation?.code || undefined,
        externalCode: allocation?.externalCode || '',
        organization: allocation?.organization,
        dateCreated: allocation?.dateCreated || '',
        dateAllocated: allocation?.dateAllocated || '',
        dateRetrieved: allocation?.dateRetrieved || '',
        dateEmailNotification: allocation?.dateEmailNotification || '',
        dateMessageNotification: allocation?.dateMessageNotification || '',
        status: { value: allocation?.status.code, label: allocation?.status.name },
        customer: { value: allocation?.customer?.fullName, label: allocation?.customer?.uuid, option: allocation?.customer },
        locker: { value: allocation?.locker?.name, label: allocation?.locker?.uuid, option: allocation?.locker },
        fullName: allocation?.organization?.fullName,
    };



    // Faz o filtro da tabela
    const handleSubmit = async (values: any) => {
        if (uuid && ['RETRIEVED', 'REVERSED'].includes(values.status.value)) {
            setShowRetrieveWarningModal(true);
        } else {
            saveAllocation(values);
        }
    };

    const saveAllocation = async (values: any) => {
        let response;

        const createProps: CustomerModel = {
            ...allocation,
            ...values,
            isActive: true,
            externalCode: values.externalCode || null,
            status: {
                isActive: true,
                code: values.status.value || 'NEW',
                name: values.status.label || 'Novo',
            },
            dateCreated: parseFormattedDate(allocation?.dateCreated) || undefined,
            dateAllocated: parseFormattedDate(allocation?.dateAllocated) || undefined,
            dateRetrieved: parseFormattedDate(allocation?.dateRetrieved) || undefined,
            dateEmailNotification: parseFormattedDate(allocation?.dateEmailNotification) || undefined,
            dateMessageNotification: parseFormattedDate(allocation?.dateMessageNotification) || undefined,
            customer: values.customer.option,
            organization: values.customer.option.organization,
            locker: values.locker.option,
            containerAllocations: uuid ? allocation?.containerAllocations : selectedPorts
        }

        if (uuid) {
            response = await allocationStore.updateAllocation(createProps, uuid);
        } else {
            response = await allocationStore.createAllocation(createProps);
        }

        if (!response.error) listRouter();
    }

    const listRouter = () => {
        navigate(`/alocacao`);
    }

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Informação Geral',
            children: <AllocationInformation setSelectedPorts={setSelectedPorts} selectedPorts={selectedPorts} />,
        },
        {
            key: '2',
            label: 'Detalhes do Alocação',
            children: <AlocationDetails />,
            disabled: uuid ? false : true,
        },
    ];

    const onChange = (key: string) => {
        setActiveTabKey(key);
    };


    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox
                    className="mb-4 p-4 lg:p-6"
                    borderCustom='rounded-b-lg'
                    title="Cadastro de Alocação"
                    description='Tela para cadastro de Alocação'
                >
                    <LoadingComponent />
                </ContentBox>
            }

            <Permission byPassValidation={!uuid} permissionKeys={[PermissionAllocation.VIEW]} containerType>
                {!loading &&
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={uuid ? editValidationSchema : validationSchema}
                    >
                        {({ values }) => (
                            <>
                                <ModalComponent
                                    title="Aviso"
                                    content={<p>
                                        Você realmente deseja atualizar a alocação como: {" "}
                                        <b>{values?.status?.label}?</b> {" "}<br /><br />
                                        <b>Obs.</b>:As portas utilizadas nesta alocação serão liberadas para uso.
                                    </p>}
                                    isModalOpen={showRetrieveWarningModal}
                                    handleOk={() => saveAllocation(values)}

                                    handleCancel={() => setShowRetrieveWarningModal(false)}
                                />
                                <Form>
                                    <Tabs
                                        className="mb-0"
                                        type="card"
                                        activeKey={activeTabKey}
                                        onChange={onChange}
                                        items={items}
                                    />


                                    <div className="flex justify-end">
                                        {uuid ?
                                            <Permission permissionKeys={[PermissionAllocation.PUT]}>
                                                <ActionButton htmlType="submit" actionType="primary">{'Atualizar'}</ActionButton>
                                            </Permission>
                                            :
                                            <Permission permissionKeys={[PermissionAllocation.POST]}>
                                                <ActionButton htmlType="submit" actionType="primary">{'Cadastrar'}</ActionButton>
                                            </Permission>
                                        }
                                    </div>

                                </Form>
                            </>
                        )}
                    </Formik >
                }
            </Permission>
        </>
    );
});

export default CreateAllocation;