import React from 'react';
import { Breadcrumb, Button } from 'antd';
import Permission from '../../molecules/Permission';

interface BreadCrumbComponentProps {
    breadCrumbList: any;
    onClick?: () => void;
    textButton?: string;
    permissionList?: string[];
}

const BreadCrumbComponent: React.FC<BreadCrumbComponentProps> = ({ breadCrumbList, onClick, textButton, permissionList = [] }) => {
    return (
        <div className="flex justify-between items-center pb-4  hidden lg:flex">
            <Breadcrumb
                items={breadCrumbList}
            />

            <Permission permissionKeys={permissionList} >
                {onClick &&
                    <Button onClick={onClick} type="primary">
                        {textButton ? textButton : 'Listagem'}
                    </Button>
                }
            </Permission>
        </div>
    );
};

export default BreadCrumbComponent;