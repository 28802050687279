import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction } from 'react';
import InputComponent from '../../../atoms/InputComponet';
import ContentBox from '../../../molecules/ContentBox';
import InputMaskComponent from '../../../atoms/InputMaskComponent';
import SelectComponent from '../../../atoms/SelectComponent';
import customerStore from '../../../../stores/customerStore';
import { useParams } from 'react-router-dom';
import organizationsStore from '../../../../stores/organizationsStore';
import ActionButton from '../../../atoms/ButtonComponent';
import AutocompleteComponent from '../../../atoms/AutocompleteComponent';

interface DataType {
    organization: any;
    email: any;
    fullName: any;
    uuid: string;
    key: React.Key;
}

interface SeparatorInformantionProps {
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}


const SeparatorInformantion: React.FC<SeparatorInformantionProps> = observer(({ setIsModalOpen, }) => {
    const { countriesList } = customerStore;
    const { uuid } = useParams<{ uuid: string }>();
    const { organizationSelect } = organizationsStore;

    const handleOrganizationSearch = async (name: string) => {
        await organizationsStore.getOrganizationsAsyncSelect(name);
    };


    return (
        <div>
            <ContentBox borderCustom='rounded-b-lg' actions={uuid && <ActionButton actionType="primary" onClick={() => setIsModalOpen(true)}>Ver QR Code</ActionButton>} className="mb-4 p-4 lg:p-6" title="Cadastro de Separador" description='Tela para cadastro de Separador'>
                <div className="flex w-full gap-4 pb-4 flex-col lg:flex-row">
                    <InputComponent label="Nome" name="firstName" id="firstName" />
                    <InputComponent label="Sobrenome" name="lastName" id="lastName" />
                    <SelectComponent label="DDI (Código do país)" name="countryCode" options={countriesList} onChange={(_, value) => customerStore.setSelectedCountry(value.option)} />
                </div>
                <div className="flex w-full gap-4 pb-4 flex-col lg:flex-row">
                    <InputMaskComponent mask={customerStore.selectedCountry?.mask ?? "(99) 99999-9999"} size="large" label="Celular" name="phone" id="phone" />
                    <InputComponent label="E-mail" name="email" id="email" />
                    {uuid === undefined && <InputComponent validateTouched={false} label="Confirmar e-mail" name="confirmEmail" id="confirmEmail" />}
                </div>
            </ContentBox>
            <ContentBox className="mb-4 p-4 lg:p-6" >
                <div className="flex w-full gap-4 pb-4">
                    <AutocompleteComponent
                        name='organization'
                        label='Organização'
                        fetchOptions={(name) => handleOrganizationSearch(name)}
                        options={organizationSelect ?? []}
                    />
                </div>
            </ContentBox>


        </div>
    );
});

export default SeparatorInformantion;