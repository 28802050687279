import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Nome obrigatório!"),
  lastName: Yup.string().required("Sobrenome obrigatório"),
  phone: Yup.string()
    .matches(/^\d{10,11}$/, "Celular deve ter 10 ou 11 dígitos"), // Apenas números, com 10 ou 11 dígitos
  email: Yup.string().email("E-mail inválido").required("Email obrigatório!"),
  organization: Yup.object().shape({
    value: Yup.string().required("Organização obrigatória!"),
    label: Yup.string(),
  }),
  countryCode: Yup.mixed().when("phone", {
    is: (phone: any) => Boolean(phone && phone.match(/^\d{10,11}$/)), // Se phone tiver um valor válido
    then: () =>
      Yup.object().shape({
        value: Yup.string().required("DDI obrigatório"),
        label: Yup.string(),
      }),
    otherwise: () =>
      Yup.object().shape({
        value: Yup.string().notRequired(),
        label: Yup.string(),
      }),
  }),
});
