import { FileDoneOutlined, HomeOutlined, InboxOutlined, SisternodeOutlined, TeamOutlined, ToolOutlined, UserOutlined } from '@ant-design/icons';
import { Menu, type MenuProps } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import { useAuth } from '../../protected/ProviderAuth';
import { PermissionAdministrator } from '../../../permissions/PermissionAdministrator';
import { PermissionCustomer } from '../../../permissions/PermissionCustomer';
import { PermissionSeparator } from '../../../permissions/PermissionSeparator';
import { PermissionOrganization } from '../../../permissions/PermissionOrganization';
import { PermissionAllocation } from '../../../permissions/PermissionAllocation';
import { PermissionCompanyFeatures } from '../../../permissions/PermissionCompanyFeatures';
import { PermissionLocker } from '../../../permissions/PermissionLocker';

type MenuItem = Required<any>['items'][number];
interface MenuComponentProps {
    collapsed?: boolean;
}

const MenuComponent: React.FC<MenuComponentProps> = ({ collapsed }) => {
    const [current, setCurrent] = useState('1');
    const navigate = useNavigate();
    const { hasPermissionList } = useAuth();

    const items: MenuItem[] = [
        {
            key: 'administrador',
            label: 'Administradores',
            icon: <TeamOutlined />,
            visible: hasPermissionList([
                PermissionAdministrator.PUT,
                PermissionAdministrator.VIEW,
                PermissionAdministrator.POST,
                PermissionAdministrator.DELETE,
                PermissionAdministrator.EXPORT,
            ]),
        },
        {
            key: 'cliente',
            label: 'Clientes',
            icon: <UserOutlined />,
            visible: hasPermissionList([
                PermissionCustomer.PUT,
                PermissionCustomer.VIEW,
                PermissionCustomer.POST,
                PermissionCustomer.EXPORT,
                PermissionCustomer.IMPORT,
            ]),
        },
        {
            key: 'separador',
            label: 'Separadores',
            icon: <SisternodeOutlined />,
            visible: hasPermissionList([
                PermissionSeparator.PUT,
                PermissionSeparator.POST,
                PermissionSeparator.EXPORT,
                PermissionSeparator.DELETE,
            ]),
        },
        {
            key: 'lockers',
            label: 'Lockers',
            icon: <InboxOutlined />,
            visible: hasPermissionList([
                PermissionLocker.PUT,
                PermissionLocker.VIEW,
                PermissionLocker.EXPORT,
            ]),
        },
        {
            key: 'organizacao',
            label: 'Organizações',
            icon: <FileDoneOutlined />,
            visible: hasPermissionList([
                PermissionOrganization.PUT,
                PermissionOrganization.VIEW,
                PermissionOrganization.POST,
                PermissionOrganization.DELETE,
                PermissionOrganization.EXPORT,
                PermissionOrganization.IMPORT,
            ]),
        },
        {
            key: 'alocacao',
            label: 'Alocações',
            icon: <InboxOutlined />,
            visible: hasPermissionList([
                PermissionAllocation.PUT,
                PermissionAllocation.VIEW,
                PermissionAllocation.POST,
                PermissionAllocation.DELETE,
                PermissionAllocation.EXPORT,
            ]),
        },
        {
            key: 'config',
            label: 'Configurações',
            icon: <ToolOutlined />,
            visible: hasPermissionList([
                PermissionCompanyFeatures.PUT,
                PermissionCompanyFeatures.VIEW,
            ]),
        },
    ];

    const onClick: MenuProps['onClick'] = (e) => {
        setCurrent(e.key);
        navigate(`/${e.key}`);
    };

    const filteredItems = items
        .filter(item => item.visible) // Filtra os itens principais com base na visibilidade
        .map(item => ({
            ...item,
            children: item.children?.filter((child: any) => child.visible), // Filtra os sub-itens (children)
        }));

    const removeVisibleKey = (items: MenuItem[]) => {
        return items.map(item => {
            // Remove a chave 'visible' do item principal
            const { visible, ...restItem } = item;

            // Se houver children, remove 'visible' de cada child também
            const newItem = {
                ...restItem,
                children: item.children?.map((child: any) => {
                    const { visible, ...restChild } = child; // Remove 'visible' do sub-item
                    return restChild;
                }),
            };

            return newItem;
        });
    };

    const menuListItems = removeVisibleKey(filteredItems);

    return (
        <div className="flex flex-col bg-white dark:bg-charcoal custom-menu-color">
            {!collapsed ?
                <div className="menu-logo flex flex-col items-center pt-6">
                    <img src="/assets/logo-white.png" alt="Logo" style={{ width: '150px' }} />
                </div> :
                <div className="menu-logo flex flex-col items-center pt-6">
                    <img src="/assets/logo-mini.png" alt="Logo" style={{ width: '40px', maxWidth: '40px' }} />
                </div>
            }

            <Menu
                theme={'light'}
                onClick={onClick}
                style={collapsed ? { width: 80 } : { width: 256 }}
                defaultOpenKeys={['sub1']}
                selectedKeys={[current]}
                inlineCollapsed={collapsed}
                mode="inline"
                items={menuListItems}// Filtra o item principal
            />
        </div>
    );
};

export default MenuComponent;