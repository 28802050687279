import { AutoComplete, Spin, Typography } from 'antd';
import { Field } from 'formik';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import './styles.scss';

interface AutocompleteComponentProps {
    label?: string;
    name: string;
    placeholder?: string;
    disabled?: boolean;
    showImage?: boolean;
    size?: any;
    fetchOptions: (searchText: string) => Promise<void>;
    options: { label: string; value: string }[];
    onChange?: (option: any) => void;
}

const AutocompleteComponent: React.FC<AutocompleteComponentProps> = ({
    label,
    size,
    name,
    placeholder,
    showImage,
    onChange,
    disabled,
    fetchOptions,
    options,
}) => {
    const [loading, setLoading] = useState(false);

    const debouncedFetch = debounce(async (fetchFunction, searchText, setLoading) => {
        if (!searchText) {
            return;
        }

        setLoading(true);
        try {
            await fetchFunction(searchText);
        } catch (error) {
            console.error("Erro ao buscar opções:", error);
        } finally {
            setLoading(false);
        }
    }, 300);

    const debouncedFetchOptions = useCallback(
        (searchText: string) => debouncedFetch(fetchOptions, searchText, setLoading),
        [debouncedFetch, fetchOptions, setLoading]
    );

    const onSearch = (searchText: string) => {
        debouncedFetchOptions(searchText);
    };

    useEffect(() => {
        return () => {
            debouncedFetch.cancel();
        };
    }, [debouncedFetch]);


    return (
        <div className="flex flex-col w-full autocomplete-container">
            {label && <Typography.Title level={5}>{label}</Typography.Title>}
            <Field name={name}>
                {({ field, form, meta }: any) => (
                    <>
                        <div>
                            <AutoComplete
                                {...field}
                                status={meta.touched && meta.error ? 'error' : ''}
                                allowClear
                                onSearch={onSearch}
                                onChange={(value) => form.setFieldValue(name, value)} // Armazena o value
                                onSelect={(value, option) => {
                                    form.setFieldValue(name, {
                                        value: option.option.label,
                                        label: option.value,
                                        option: option?.option?.option,
                                    });
                                    onChange && onChange(option)
                                }}
                                options={options && options?.map((option: any) => ({
                                    label: (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {showImage && (
                                                <img
                                                    src={option?.option?.files[0]?.path || 'https://via.placeholder.com/40'}
                                                    alt="Product"
                                                    style={{ width: 40, height: 40, marginRight: 8 }}
                                                />
                                            )}
                                            <span>{option.label}</span>
                                        </div>
                                    ),
                                    value: option.value,
                                    option: option,
                                }))}
                                placeholder={placeholder || 'Digite para Pesquisar'}
                                disabled={disabled}
                                size={size ? size : 'large'}
                                style={{ width: '100%' }}
                            />
                            {loading && <Spin />}
                            {meta.touched && meta.error?.value && (
                                <Typography.Text type="danger">{meta.error?.value}</Typography.Text>
                            )}
                        </div>
                    </>
                )}
            </Field>
        </div>
    );
};

export default AutocompleteComponent;
