export const DEFAULT_PAGE_SIZE = 10;
export const COLOR_TOOLTIP = "#292e39"


// Loading
export const LOADING_TEXT = '...Carregando Informação';

// Header
export const TEXT_BUTTON_HEADER_LIST = 'Listagem';
export const TEXT_BUTTON_HEADER_CREATE = 'Cadastrar';


export const defaultparamsExport = {
    size: 999999,
    page: 0,
    sort: 'created,desc',
    search: '',
}