import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Space, Table, TableColumnsType } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import organizationsStore from '../../../stores/organizationsStore';
import { OrganizationModel } from '../../../types/OrganizationModel';
import { shortState } from '../../../utils/selects';
import { getUniqueFilters } from '../../../utils/tableFunctions';
import AutocompleteComponent from '../../atoms/AutocompleteComponent';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import InputComponet from '../../atoms/InputComponet';
import InputMaskComponent from '../../atoms/InputMaskComponent';
import SelectComponent from '../../atoms/SelectComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import { validationSchema } from './schema';
import Permission from '../../molecules/Permission';
import { PermissionOrganization } from '../../../permissions/PermissionOrganization';

const breadCrumb = [
    {
        title: <a href="/organizacao">Listagem de Organização</a>,
    },
    {
        title: <p>Cadastro de Organização</p>,
    },
];
interface DataTypeCustomer {
    uuid: string;
    firstName: string;
    lastName: string;
    phone: string;
    key: React.Key;
}

interface DataTypeChildren {
    uuid: string;
    firstName: string;
    lastName: string;
    phone: string;
    key: React.Key;
}

const CreateOrganization: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { organization, organizationSelect, organizationCustomers, loading } = organizationsStore;

    const navigate = useNavigate();

    const handleOrganizationSearch = async (name: string) => {
        await organizationsStore.getOrganizationsAsyncSelect(name);
    };


    useEffect(() => {
        const getInformation = async () => {
            if (uuid) {
                await organizationsStore.getOrganizationByUuid(uuid);
                await organizationsStore.getOrganizationCustomers(uuid);
                document.title = `Organização - ${organizationsStore.organization?.fullName}`;
            }else {
                document.title = `Cadastro de Organização`
            }
        };

        const getOrganizationsTree = async () => {
            await organizationsStore.getTree();
        };

        getInformation();
        getOrganizationsTree();

        return () => {
            organizationsStore.reset();
        };
    }, [uuid]);


    const initialValues: any = {
        name: organization?.name || '',
        street: organization?.address.street || '',
        number: organization?.address.number || '',
        district: organization?.address.district || '',
        zipCode: organization?.address.zipCode || '',
        city: organization?.address.city || '',
        state: { label: organization?.address.state, value: organization?.address.state },
        country: organization?.address.country || '',
        complement: organization?.address.complement || '',
        parent: { value: organization?.parent?.fullName, label: organization?.parent?.uuid, option: organization?.parent },
        children: organization?.children || '',
    };

    // Faz o filtro da tabela
    const handleSubmit = async (values: {
        name: string;
        street: string;
        number: number;
        district: string;
        zipCode: number;
        city: string;
        state: { label: string, value: string };
        country: string;
        complement: string;
        parent: { value: string, label: string, option: OrganizationModel };
    }) => {
        let response;
        const payload: OrganizationModel = {
            ...organization,
            name: values.name,
            parent: values.parent.option || {} as OrganizationModel,
            address: {
                ...organization?.address,
                street: values.street,
                number: values.number,
                district: values.district,
                zipCode: values.zipCode,
                city: values.city,
                state: values.state.value,
                complement: values.complement,
                country: "Brasil",
            },
        }

        if (uuid) {
            response = await organizationsStore.editOrganization(payload, uuid);
        } else {
            response = await organizationsStore.createOrganization(payload);
        }

        if (!response.error) listRouter();
    };

    const listRouter = () => {
        navigate(`/organizacao`);
    };

    const customerColumns: TableColumnsType<DataTypeCustomer> = [
        {
            title: 'Nome',
            dataIndex: 'firstName',
            key: 'firstName',
            filters: getUniqueFilters(organizationCustomers, 'firstName'),
            onFilter: (value, record) => record.firstName.indexOf(value as string) === 0,
        },
        {
            title: 'Sobrenome',
            dataIndex: 'lastName',
            key: 'lastName',
            filters: getUniqueFilters(organizationCustomers, 'lastName'),
            onFilter: (value, record) => record.lastName.indexOf(value as string) === 0,
        },
        {
            title: 'Telefone',
            dataIndex: 'phone',
            key: 'phone',
            filters: getUniqueFilters(organizationCustomers, 'phone'),
            onFilter: (value, record) => record.phone.indexOf(value as string) === 0,
        },
        {
            title: 'Visualizar',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <a href={`/cliente/visualizar/${record.uuid}`}>
                        <div><EyeOutlined /></div>
                    </a>
                </Space>
            ),
        },
    ];

    const childrenColumns: TableColumnsType<DataTypeChildren> = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            filters: getUniqueFilters(organization?.children || [], 'firstName'),
            onFilter: (value, record) => record.firstName.indexOf(value as string) === 0,
        },
        {
            title: 'Visualizar',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <a href={`/organizacao/visualizar/${record.uuid}`}>
                        <div><EyeOutlined /></div>
                    </a>
                </Space>
            ),
        },
    ];


    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-4 lg:p-6" title="Cadastro de Organização" description='Tela para cadastro de Organização'>
                    <LoadingComponent />
                </ContentBox>
            }
            <Permission byPassValidation={!uuid} permissionKeys={[PermissionOrganization.VIEW]} containerType>
                {!loading &&
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        <Form>
                            <ContentBox className="mb-4 p-4 lg:p-6" title="Cadastro de Organização" description='Tela para cadastro de Organização'>
                                <div className="flex w-full gap-4 pb-4 flex-col lg:flex-row">
                                    <InputComponet required label="Nome" name="name" id="name" />
                                    <InputComponet required label="Rua" name="street" id="street" />
                                    <InputComponet required label="Número" name="number" id="number" />

                                </div>
                                <div className="flex flex-col lg:grid grid-cols-12 gap-4">
                                    <div className="col-span-4">
                                        <InputComponet required label="Bairro" name="district" id="district" />

                                    </div>
                                    <div className="col-span-4">
                                        <div className="grid grid-cols-12 gap-4">
                                            <div className="col-span-4">
                                                <SelectComponent label="Estado" name="state" options={shortState} />
                                            </div>
                                            <div className="col-span-8">
                                                <InputComponet required label="Cidade" name="city" id="city" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-span-4">
                                        <InputMaskComponent required mask="99999-999" label="CEP" name="zipCode" id="zipCode" size="large" />
                                    </div>
                                </div>

                            </ContentBox>

                            <ContentBox className="mb-4 p-4 lg:p-6" >
                                <div className="flex w-full gap-4 pb-4">
                                    <AutocompleteComponent
                                        name='parent'
                                        label='Organização pai'
                                        fetchOptions={(name) => handleOrganizationSearch(name)}
                                        options={organizationSelect ?? []}
                                    />
                                </div>
                            </ContentBox>

                            {uuid && <ContentBox className="mb-4 p-4 lg:p-6" description='Organizações filhas'>
                                <Table
                                    className="table-custom"
                                    columns={childrenColumns}
                                    dataSource={organization?.children?.map((item: any, index: number) => ({
                                        ...item,
                                        key: item.uuid || index,
                                    }))}
                                    loading={loading}
                                    size="middle"
                                    bordered={false}
                                />
                            </ContentBox>}

                            {uuid && <ContentBox className="mb-4 p-6" description='Clientes'>
                                <Table
                                    className="table-custom"
                                    columns={customerColumns}
                                    dataSource={organizationCustomers?.map((item: any, index: number) => ({
                                        ...item,
                                        key: item.uuid || index,
                                    }))}
                                    loading={loading}
                                    size="middle"
                                    bordered={false}
                                />
                            </ContentBox>}

                            <div className="flex justify-end">
                                {uuid ?
                                    <Permission permissionKeys={[PermissionOrganization.PUT]}>
                                        <ActionButton htmlType="submit" actionType="primary">{'Atualizar'}</ActionButton>
                                    </Permission>
                                    :
                                    <Permission permissionKeys={[PermissionOrganization.POST]}>
                                        <ActionButton htmlType="submit" actionType="primary">{'Cadastrar'}</ActionButton>
                                    </Permission>
                                }
                            </div>
                        </Form>
                    </Formik >
                }
            </Permission>

        </>
    );
});

export default CreateOrganization;