import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Space, Table, TableColumnsType, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import lockersStore from '../../../../stores/lockersStore';
import { formatDateAndTime } from '../../../../utils/dateFunctions';
import { getUniquePropertyFilters } from '../../../../utils/tableFunctions';
import ContentBox from '../../../molecules/ContentBox';
import { getStatusOrderColor } from '../../../../utils/statusColorMapper';
import TableCardComponent from '../../../molecules/TableCardComponent';

interface DataType {
    customer: any;
    organization: any;
    email: any;
    fullName: any;
    uuid: string;
    key: React.Key;
}


const ListAlocattions: React.FC = observer(() => {
    const { locker, loading, allocationList } = lockersStore;

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Código',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Nome',
            dataIndex: 'customer',
            key: 'customer.fullName',
            filters: getUniquePropertyFilters<any, 'customer', 'fullName'>(allocationList, 'customer', 'fullName'),
            onFilter: (value, record) => record.customer?.fullName.indexOf(value as string) === 0, // Acesse o nome do canal
            render: (item) => {
                return (
                    <>{item && <p>{item?.fullName}</p>}</>
                );
            },
        },
        {
            title: 'Dt. Criação',
            dataIndex: 'dateCreated',
            key: 'dateCreated',
        },
        {
            title: 'Dt. Alocação',
            dataIndex: 'dateAllocated',
            key: 'dateAllocated',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status.name',
            sorter: true,
            render: (status) => {
                return (
                    <Tag color={getStatusOrderColor(status?.code)}>{(status?.name)}</Tag>
                );
            },
        },
        {
            title: 'Portas',
            dataIndex: 'containerAllocations',
            key: 'containerAllocations',
            sorter: true,
            render: (item) => {
                return (
                    <div className="flex gap-2">
                        {item.map((item: any) => (
                            <Tag color="purple" className="mr-0">P-{item.container.name}</Tag>
                        ))}
                    </div >
                );
            },
        },
        {
            title: 'Visualizar',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            width: 80,
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <a href={`/alocacao/visualizar/${record.uuid}`}>
                        <div><EyeOutlined /></div>
                    </a>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <ContentBox className="mb-4 p-4 hidden lg:block p-6" borderCustom='rounded-b-lg' title="Visualizar Alocações" description='Visão Geral de alocações dentro do locker'>
                <Table
                    className="table-custom"
                    columns={columns}
                    dataSource={allocationList && allocationList.map((item: any, index: number) => ({
                        ...item,
                        key: item.uuid || index,
                    }))}
                    loading={loading}
                    size="middle"
                    bordered={false}
                    pagination={false}
                />
            </ContentBox>

            <TableCardComponent
                itemData={allocationList}
                titlePage="Visualizar Alocações"
                viewItems={[{ name: 'Organização', key: 'organization.fullName' }, { name: 'Dt. Criação', key: 'dateCreated' }, { name: 'Dt. Alocação', key: 'dateAllocated' }, { name: 'Status', key: 'status.name', type: 'tag' }, { name: "Portas", key: 'ports', type: 'tag' }]}
                titleKey="customer.firstName"
                subTitleKey="code"
                inputName="fullName__icontains"
                placeholder="Pesquisar Separador"
                loading={loading}
            />
        </div>
    );
});

export default ListAlocattions;