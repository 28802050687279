// src/components/organisms/LoginForm/index.tsx
import { FileDoneOutlined, InboxOutlined, LogoutOutlined, SisternodeOutlined, TeamOutlined, ToolOutlined, UserOutlined } from '@ant-design/icons';
import { Drawer, Menu, MenuProps } from 'antd';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import Cookies from 'js-cookie';
import { COOKIE_NAMES } from '../../../config/cookieConstants';
import UserHeaderComponent from '../../molecules/UserHeaderComponent';
import authStore from '../../../stores/authStore';
import { PermissionCompanyFeatures } from '../../../permissions/PermissionCompanyFeatures';
import { PermissionAllocation } from '../../../permissions/PermissionAllocation';
import { PermissionOrganization } from '../../../permissions/PermissionOrganization';
import { PermissionLocker } from '../../../permissions/PermissionLocker';
import { PermissionSeparator } from '../../../permissions/PermissionSeparator';
import { PermissionCustomer } from '../../../permissions/PermissionCustomer';
import { PermissionAdministrator } from '../../../permissions/PermissionAdministrator';
import { useAuth } from '../../protected/ProviderAuth';

interface DrawerMenuProps {
    openMenu: boolean;
    setOpenMenu: Dispatch<SetStateAction<boolean>>;
}

type MenuItem = Required<any>['items'][number];

const DrawerMenu: React.FC<DrawerMenuProps> = ({ setOpenMenu, openMenu }) => {
    const [current, setCurrent] = useState('1');
    const navigate = useNavigate();
    const email = Cookies.get(COOKIE_NAMES.EMAIL);
    const { hasPermissionList } = useAuth();


    const items: MenuItem[] = [
        {
            key: 'administrador',
            label: 'Administradores',
            icon: <TeamOutlined />,
            visible: hasPermissionList([
                PermissionAdministrator.PUT,
                PermissionAdministrator.POST,
                PermissionAdministrator.VIEW,
                PermissionAdministrator.DELETE,
                PermissionAdministrator.EXPORT,
            ]),
        },
        {
            key: 'cliente',
            label: 'Clientes',
            icon: <UserOutlined />,
            visible: hasPermissionList([
                PermissionCustomer.PUT,
                PermissionCustomer.VIEW,
                PermissionCustomer.POST,
                PermissionCustomer.EXPORT,
                PermissionCustomer.IMPORT,
            ]),
        },
        {
            key: 'separador',
            label: 'Separadores',
            icon: <SisternodeOutlined />,
            visible: hasPermissionList([
                PermissionSeparator.PUT,
                PermissionSeparator.POST,
                PermissionSeparator.VIEW,
                PermissionSeparator.EXPORT,
                PermissionSeparator.DELETE,
            ]),
        },
        {
            key: 'lockers',
            label: 'Lockers',
            icon: <InboxOutlined />,
            visible: hasPermissionList([
                PermissionLocker.PUT,
                PermissionLocker.EXPORT,
                PermissionLocker.VIEW,
            ]),
        },
        {
            key: 'organizacao',
            label: 'Organizações',
            icon: <FileDoneOutlined />,
            visible: hasPermissionList([
                PermissionOrganization.PUT,
                PermissionOrganization.VIEW,
                PermissionOrganization.POST,
                PermissionOrganization.DELETE,
                PermissionOrganization.EXPORT,
                PermissionOrganization.IMPORT,
            ]),
        },
        {
            key: 'alocacao',
            label: 'Alocações',
            icon: <InboxOutlined />,
            visible: hasPermissionList([
                PermissionAllocation.PUT,
                PermissionAllocation.POST, 
                PermissionAllocation.VIEW,
                PermissionAllocation.DELETE,
                PermissionAllocation.EXPORT,
            ]),
        },
        {
            key: 'config',
            label: 'Configurações',
            icon: <ToolOutlined />,
            visible: hasPermissionList([
                PermissionCompanyFeatures.PUT,
                PermissionCompanyFeatures.VIEW,
            ]),
        },
        {
            key: 'logout',
            label: 'Desconectar',
            icon: <LogoutOutlined />,
            visible: true,
            onClick: () => authStore.logout(),
        },
    ];


    const onClick: MenuProps['onClick'] = (e) => {
        setCurrent(e.key);
        navigate(`/${e.key}`);
    };

    const filteredItems = items
        .filter(item => item.visible) // Filtra os itens principais com base na visibilidade
        .map(item => ({
            ...item,
            children: item.children?.filter((child: any) => child.visible), // Filtra os sub-itens (children)
        }));

    return (
        <Drawer title={null} headerStyle={{ display: 'none' }} bodyStyle={{ padding: '16px' }} style={{ background: '#292e39', color: "#fff" }} width={280} placement={'left'} onClose={() => setOpenMenu(false)} open={openMenu}>
            <UserHeaderComponent />

            <div className="menu-modal pt-4">
                <Menu
                    theme={'light'}
                    onClick={onClick}
                    defaultOpenKeys={['sub1']}
                    selectedKeys={[current]}
                    mode="inline"
                    items={filteredItems}// Filtra o item principal
                />
            </div>
        </Drawer>
    );
};

export default DrawerMenu;