import { EditOutlined, EyeOutlined, MoreOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { Space, Table, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE, TEXT_BUTTON_HEADER_CREATE } from '../../../config/config';
import useQueryParams, { buildParamsFromQuery } from '../../../hooks/useQueryParams';
import allocationStore from '../../../stores/allocationStore';
import { CustomerModel } from '../../../types/CustomerModel';
import { OrganizationModel } from '../../../types/OrganizationModel';
import { formatFromDatePicker } from '../../../utils/dateFunctions';
import { exportToExcel } from '../../../utils/excelFunction';
import { getStatusColor, getStatusOrderColor } from '../../../utils/statusColorMapper';
import { getSortField, getSortOrder } from '../../../utils/tableFunctions';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import ContentBox from '../../molecules/ContentBox';
import TableCardComponent from '../../molecules/TableCardComponent';
import DrawerBottom from '../../organisms/DrawerButton';
import DrawerFilterAllocation from './DrawerFilterAllocation';
import FormAllocation from './FormAllocation';
import { PermissionAllocation } from '../../../permissions/PermissionAllocation';
import { useAuth } from '../../protected/ProviderAuth';
import Permission from '../../molecules/Permission';

interface DataType {
    uuid: string;
    code: string;
    externalCode: string;
    customer: CustomerModel;
    organization: OrganizationModel;
    locker: any;
    dateCreated: string;
    dateAllocated: string;
    dateRetrieved: string;
    dateMessageNotification: string;
    dateEmailNotification: string;
    containerAllocations: string;
    status: string;
    key: React.Key;
}

interface QueryParams {
    size?: number;
    page?: number;
    sort?: string;
    code__icontains?: string;
    customer__fullName__icontains?: string;
    locker__name__icontains?: string;
    containerAllocations__container__name__icontains?: string;
    status__name__icontains?: string;
    dateMessageNotification__isnull?: string;
    dateEmailNotification__isnull?: string;
    dateCreated__gte?: string;
    dateCreated__lte?: string;
    dateAllocated__gte?: string;
    dateAllocated__lte?: string;
    dateRetrieved__gte?: string;
    dateRetrieved__lte?: string;
    containerAllocations__observations__icontains?: string;
    allocationMethod?: string;
}

// BreadCrumb
const breadCrumb = [
    {
        title: <p>Lista de Alocações</p>,
    },
]

const ListAllocations: React.FC = observer(() => {
    const { allocationList, loading, page, totalElements, pageSize, sort } = allocationStore;
    const [queryParams, setQueryParams] = useQueryParams();
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const navigate = useNavigate();
    const listRouter = () => { navigate(`/alocacao/cadastro`); }
    let formikSubmit: () => void;
    const [bottomOptions, openBottomOptions] = useState<any>(false);
    const { hasPermission, isColumnVisible } = useAuth();

    useEffect(() => {
        document.title = 'Alocações'
        const mappings = {
            page: 'page',
            pageSize: 'pageSize',
            sort: 'sort',
            code__icontains: 'code__icontains',
            customer__fullName__icontains: 'customer__fullName__icontains',
            organization__fullName__icontains: 'organization__fullName__icontains',
            locker__name__icontains: 'locker__name__icontains',
            containerAllocations__container__name__icontains: 'containerAllocations__container__name__icontains',
            status__name__icontains: 'status__name__icontains',
            dateMessageNotification__isnull: 'dateMessageNotification__isnull',
            dateEmailNotification__isnull: 'dateEmailNotification__isnull',
            dateCreated__gte: 'dateCreated__gte',
            dateCreated__lte: 'dateCreated__lte',
            dateAllocated__gte: 'dateAllocated__gte',
            dateAllocated__lte: 'dateAllocated__lte',
            dateRetrieved__gte: 'dateRetrieved__gte',
            dateRetrieved__lte: 'dateRetrieved__lte',
            containerAllocations__observations__icontains: 'containerAllocations__observations__icontains',
            allocationMethod: 'allocationMethod'
        };

        const params = buildParamsFromQuery(queryParams, mappings);

        const fetchData = async () => {
            const urlParams = new URLSearchParams({
                page: String(params.page),
                pageSize: String(params.pageSize ? params.pageSize : DEFAULT_PAGE_SIZE),
                sort: String(params.sort),
            });

            // Adiciona cada filtro separadamente no URLSearchParams
            params.filter.forEach((filter: string) => urlParams.append('filter', filter));

            await allocationStore.getList(urlParams);
        };

        fetchData();
    }, []);


    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Código',
            dataIndex: 'code',
            key: 'code',
            defaultSortOrder: getSortOrder('code', queryParams.sort),
            sorter: true,
        },
        {
            title: 'Cliente',
            dataIndex: 'customer',
            key: 'customer__fullName',
            defaultSortOrder: getSortOrder('customer__fullName', queryParams.sort),
            sorter: true,
            render: (item) => {
                return (
                    <>{item && <p>{item?.fullName}</p>}</>
                );
            },
        },
        {
            title: 'Organização',
            dataIndex: 'organization',
            key: 'organization__fullName',
            defaultSortOrder: getSortOrder('organization__fullName', queryParams.sort),
            sorter: true,
            render: (item) => {
                return (
                    <>{item && <p>{item?.fullName}</p>}</>
                );
            },
        },
        {
            title: 'Locker',
            dataIndex: 'locker',
            key: 'locker.name',
            defaultSortOrder: getSortOrder('locker__name', queryParams.sort),
            sorter: true,
            render: (item) => {
                return (
                    <>{item && <p>{item?.name}</p>}</>
                );
            },
        },
        {
            title: 'Dt. Alocação',
            dataIndex: 'dateAllocated',
            key: 'dateAllocated',
            defaultSortOrder: getSortOrder('dateAllocated', queryParams.sort),
            sorter: true,
        },
        {
            title: 'Dt. Retirada',
            dataIndex: 'dateRetrieved',
            key: 'dateRetrieved',
            defaultSortOrder: getSortOrder('dateRetrieved', queryParams.sort),
            sorter: true,
        },
        {
            title: 'Mensagem enviada',
            dataIndex: 'dateMessageNotification',
            key: 'dateMessageNotification',
            defaultSortOrder: getSortOrder('dateMessageNotification', queryParams.sort),
            sorter: true,
            render: (active) => {
                let text = active ? 'Sim' : 'Não';
                return (
                    <Tag color={getStatusColor(text)}><p>{text}</p></Tag>
                );
            },
        },
        {
            title: 'Portas',
            dataIndex: 'containerAllocations',
            key: 'containerAllocations',
            defaultSortOrder: getSortOrder('containerAllocations', queryParams.sort),
            sorter: true,
            render: (allocations) => {
                return (
                    <div className="flex flex-col gap-1">
                        {allocations.map((allocation: any) => (
                            <Tag color={allocation.inLocker ? "green" : "red"}>{allocation.container.name}-{allocation.container.containerMeasure.name}</Tag>
                        ))}
                    </div>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            defaultSortOrder: getSortOrder('status__name', queryParams.sort),
            sorter: true,
            render: (status) => {
                return (
                    <Tag color={getStatusOrderColor(status.code)}>{status?.name}</Tag>
                );
            },
        },
        {
            title: 'Visualizar',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            hidden: !isColumnVisible(PermissionAllocation.VIEW),
            width: 80,
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <a href={`/alocacao/visualizar/${record.uuid}`}>
                        <div><EyeOutlined /></div>
                    </a>
                </Space>
            ),
        },
    ];

    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        const params = buildParams(pagination, sorter, queryParams);
        await allocationStore.getList(params);
    };

    const buildParams = (
        pagination: any,
        sorter: any,
        queryParams: QueryParams
    ) => {
        const getSort = getSortField(sorter);
        const currentPage = pagination.current || pagination;
        const pageSize = pagination.pageSize || queryParams.size || DEFAULT_PAGE_SIZE;
        const paramsUrl = {
            code__icontains: queryParams.code__icontains || undefined,
            customer__fullName__icontains: queryParams.customer__fullName__icontains || undefined,
            locker__name__icontains: queryParams.locker__name__icontains || undefined,
            containerAllocations__container__name__icontains: queryParams.containerAllocations__container__name__icontains || undefined,
            status__name__icontains: queryParams.status__name__icontains || undefined,
            dateMessageNotification__isnull: queryParams?.dateMessageNotification__isnull || undefined,
            dateEmailNotification__isnull: queryParams?.dateEmailNotification__isnull || undefined,
            dateCreated__gte: queryParams?.dateCreated__gte || undefined,
            dateCreated__lte: queryParams?.dateCreated__lte || undefined,
            dateAllocated__gte: queryParams?.dateAllocated__gte || undefined,
            dateAllocated__lte: queryParams?.dateAllocated__lte || undefined,
            dateRetrieved__gte: queryParams?.dateRetrieved__gte || undefined,
            dateRetrieved__lte: queryParams?.dateRetrieved__lte || undefined,
            containerAllocations__observations__icontains: queryParams.containerAllocations__observations__icontains || undefined,
            allocationMethod: queryParams?.allocationMethod || undefined
        };

        const params = new URLSearchParams({
            page: currentPage,
            pageSize: String(pageSize),
            sort: getSort || sort || queryParams.sort || '-date_created',
        });

        Object.entries(paramsUrl)
            .filter(([_, value]) => (value !== undefined))
            .forEach(([key, value]: [any, any]) => params.append('filter', `${key}=${value}`));

        setQueryParams({
            ...paramsUrl,
            pageSize: pageSize,
            page: currentPage,
            sort: getSort || sort || queryParams.sort || '-date_created',
        });
        return params;
    };

    const handleExport = async () => {
        const params = new URLSearchParams();

        Object.entries(queryParams)
            .filter(([_, value]) => value !== undefined)
            .forEach(([key, value]: [any, any]) => params.append('filter', `${key}=${value}`));

        const dataExport = await allocationStore?.export(params);

        if (dataExport) {
            exportToExcel(dataExport, "Tabela de Alocação.csv");
        }
    };

    const initialValues = {
        code__icontains: queryParams.code__icontains || undefined,
        customer__fullName__icontains: queryParams.customer__fullName__icontains || undefined,
        locker__name__icontains: queryParams.locker__name__icontains || undefined,
        containerAllocations__container__name__icontains: queryParams.containerAllocations__container__name__icontains || undefined,
        status__name__icontains: queryParams.status__name__icontains || undefined,
        dateMessageNotification__isnull: queryParams?.dateMessageNotification__isnull || undefined,
        dateEmailNotification__isnull: queryParams?.dateEmailNotification__isnull || undefined,
        dateCreated__gte: queryParams?.dateCreated__gte || undefined,
        dateCreated__lte: queryParams?.dateCreated__lte || undefined,
        dateAllocated__gte: queryParams?.dateAllocated__gte || undefined,
        dateAllocated__lte: queryParams?.dateAllocated__lte || undefined,
        dateRetrieved__gte: queryParams?.dateRetrieved__gte || undefined,
        dateRetrieved__lte: queryParams?.dateRetrieved__lte || undefined,
        containerAllocations__observations__icontains: queryParams.containerAllocations__observations__icontains || undefined,
        allocationMethod: queryParams.allocationMethod || undefined
    };

    const handleSubmit = async (values: any) => {
        const dateCreated = formatFromDatePicker(values.dateCreated, "YYYY-MM-DD");
        const dateAllocated = formatFromDatePicker(values.dateAllocated, "YYYY-MM-DD");
        const dateRetrieved = formatFromDatePicker(values.dateRetrieved, "YYYY-MM-DD");

        const newParams: any = {
            code__icontains: values.code__icontains || undefined,
            customer__fullName__icontains: values.customer__fullName__icontains || undefined,
            locker__name__icontains: values.locker__name__icontains || undefined,
            containerAllocations__container__name__icontains: values.containerAllocations__container__name__icontains || undefined,
            status__name__icontains: values.status__name__icontains || undefined,
            dateMessageNotification__isnull: values?.dateMessageNotification__isnull?.value || undefined,
            dateEmailNotification__isnull: values?.dateEmailNotification__isnull?.value || undefined,
            dateCreated__gte: dateCreated ? dateCreated[0] : undefined,
            dateCreated__lte: dateCreated ? dateCreated[1] : undefined,
            dateAllocated__gte: dateAllocated ? dateAllocated[0] : undefined,
            dateAllocated__lte: dateAllocated ? dateAllocated[1] : undefined,
            dateRetrieved__gte: dateRetrieved ? dateRetrieved[0] : undefined,
            dateRetrieved__lte: dateRetrieved ? dateRetrieved[1] : undefined,
            containerAllocations__observations__icontains: values.containerAllocations__observations__icontains || undefined,
            allocationMethod: values.allocationMethod || undefined
        };

        // Usando URLSearchParams para montar múltiplos parâmetros `filter`
        const params = new URLSearchParams({
            page: '1',
            pageSize: String(DEFAULT_PAGE_SIZE),
        });

        Object.entries(newParams)
            .filter(([_, value]) => value !== undefined)
            .forEach(([key, value]: [any, any]) => params.append('filter', `${key}=${value}`));

        // Definindo os query params atualizados
        setQueryParams(newParams);

        // Chamando a API com os parâmetros múltiplos
        await allocationStore.getList(params);
    };

    const editRouter = (item: any) => {
        navigate(`/alocacao/visualizar/${item.uuid}`);
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permissionList={[PermissionAllocation.POST]} textButton={TEXT_BUTTON_HEADER_CREATE} onClick={() => listRouter()} />

            <ContentBox className="mb-4 p-6 hidden lg:block" title="Listagem de Alocações" description='Tela de listagem de Alocações'>
                <FormAllocation
                    setSubmitForm={(submitForm) => {
                        formikSubmit = submitForm;
                    }}
                    queryParams={queryParams} setQueryParams={setQueryParams}
                />
            </ContentBox >

            <ContentBox className="mb-4 p-6 hidden lg:block" description='Lista de Alocações' actions={
                <>
                    <Permission permissionKeys={[PermissionAllocation.EXPORT]} removeType>
                        <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                    </Permission>
                </>
            }>
                <Table
                    className="table-custom"
                    columns={columns}
                    dataSource={allocationList.map((item: any, index: number) => ({
                        ...item,
                        key: item.uuid || index,
                    }))}
                    onChange={onChange}
                    loading={loading}
                    size="middle"
                    bordered={false}
                    pagination={{
                        current: page,
                        pageSize: pageSize,
                        total: totalElements,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', '40'],
                    }}
                />
            </ContentBox>

            {/* Responsive Component */}
            <TableCardComponent
                itemData={allocationList}
                titlePage="Alocações"
                subTitlePage="Tela de listagem de Alocações"
                viewItems={[
                    { name: 'Organização', key: 'organization.fullName' },
                    { name: 'Locker', key: 'locker.name' },
                    { name: 'Dt. Alocação', key: 'dateAllocated' },
                    { name: 'Dt. Retirada', key: 'dateRetrieved' },
                    { name: 'Mensagem enviada', key: 'dateMessageNotification' },
                    { name: 'Dt. Criação', key: 'dateCreated' },
                    { name: 'Status', key: 'status.name', type: 'tag' },
                    { name: "Portas", key: 'ports', type: 'tag' },
                ]}
                titleKey="customer.firstName"
                subTitleKey="code"
                initialValues={initialValues}
                totalElements={totalElements}
                pageSize={pageSize}
                current={page}
                onChange={onChange}
                editRouter={isColumnVisible(PermissionAllocation.PUT) ? editRouter : undefined}
                handleSubmit={handleSubmit}
                inputName="name__icontains"
                placeholder="Pesquisar Alocações"
                loading={loading}
                actions={
                    <>
                        <div style={{ background: '#FFFAF2' }} onClick={() => openBottomOptions(true)} className="bg-slate-50 flex min-w-10 min-h-10 justify-center rounded-full">
                            <MoreOutlined />
                        </div>
                    </>
                }
            />

            <DrawerFilterAllocation queryParams={queryParams} setQueryParams={setQueryParams} isModalOpen={openFilter} setIsModalOpen={setOpenFilter} />

            <DrawerBottom openMenu={bottomOptions} setOpenMenu={openBottomOptions}>
                <p className="h-8" onClick={() => {
                    setOpenFilter(true);
                    openBottomOptions(false);
                }}>Filtro</p>

                <Permission permissionKeys={[PermissionAllocation.POST]} removeType>
                    <p className="h-8" onClick={() => listRouter()}>Cadastrar Alocação</p>
                </Permission>

                <Permission permissionKeys={[PermissionAllocation.EXPORT]} removeType>
                    <p className="h-8" onClick={() => {
                        handleExport();
                        openBottomOptions(false);
                    }}>Exportar para Excel</p>
                </Permission>
            </DrawerBottom>
        </>
    );
});

export default ListAllocations;