import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction } from 'react';
import InputComponent from '../../../atoms/InputComponet';
import InputNumberComponente from '../../../atoms/InputNumberComponet';
import ContentBox from '../../../molecules/ContentBox';
import Tree from 'rc-tree';
import InputMaskComponent from '../../../atoms/InputMaskComponent';
import SelectComponent from '../../../atoms/SelectComponent';
import customerStore from '../../../../stores/customerStore';
import { useParams } from 'react-router-dom';
import { TreeProps } from 'antd';
import { OrganizationModel } from '../../../../types/OrganizationModel';
import { AddressModel } from '../../../../types/AddressModel';
import organizationsStore from '../../../../stores/organizationsStore';
import AutocompleteComponent from '../../../atoms/AutocompleteComponent';

interface DataType {
    organization: any;
    email: any;
    fullName: any;
    uuid: string;
    key: React.Key;
}

interface AdministratorInformationProps {
    organizationSelected: OrganizationModel | undefined;
    setOrganizationSelected: Dispatch<SetStateAction<OrganizationModel | undefined>>;
}


const AdministratorInformation: React.FC<AdministratorInformationProps> = observer(({ organizationSelected, setOrganizationSelected }) => {
    const { countriesList } = customerStore;
    const { organizationSelect } = organizationsStore;
    const { uuid } = useParams<{ uuid: string }>();


    const handleOrganizationSearch = async (name: string) => {
        await organizationsStore.getOrganizationsAsyncSelect(name);
    };

    const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
        if (selectedKeys.length > 0) {
            const organizationSelected: any = { ...info.node }
            const newParent: OrganizationModel = {
                uuid: organizationSelected?.uuid || "",
                name: organizationSelected?.name || "",
                address: organizationSelected?.address || {} as AddressModel,
                parent: organizationSelected?.parent || {} as OrganizationModel,
                children: organizationSelected?.children || [],
                dateCreated: organizationSelected?.dateCreated || "",
                dateModified: organizationSelected?.dateModified || "",
            }
            setOrganizationSelected(newParent);
        } else {
            setOrganizationSelected(undefined);
        }
    };

    return (
        <div>
            <ContentBox className="mb-4 p-4 lg:p-6" borderCustom='rounded-b-lg' title="Cadastro de Administrator" description='Tela para cadastro de Administrator'>
                <div className="flex w-full gap-4 pb-4 flex-col lg:flex-row">
                    <InputComponent label="Nome" name="firstName" id="firstName" />
                    <InputComponent label="Sobrenome" name="lastName" id="lastName" />
                    <SelectComponent label="DDI (Código do país)" name="countryCode" options={countriesList} onChange={(_, value) => customerStore.setSelectedCountry(value.option)} />
                </div>
                <div className="flex flex-col lg:grid grid-cols-12 gap-4">
                    <div className="col-span-4">
                        <InputMaskComponent mask={customerStore.selectedCountry?.mask ?? "(99) 99999-9999"} size="large" label="Celular" name="phone" id="phone" />
                    </div>
                    <div className="col-span-4">
                        <InputComponent label="E-mail" name="email" id="email" />
                    </div>
                    <div className="col-span-4">
                        {uuid === undefined && <InputComponent label="Confirmar e-mail" name="confirmEmail" id="confirmEmail" validateTouched={false} />}
                    </div>
                </div>
            </ContentBox>
            <ContentBox className="mb-4 p-4 lg:p-6"

                description='Selecione uma organização da qual o administrador será responsável'
            >
                <div className="flex w-full gap-4 pb-4">
                    <AutocompleteComponent
                        name='organization'
                        label='Organização'
                        fetchOptions={(name) => handleOrganizationSearch(name)}
                        options={organizationSelect ?? []}
                    />
                </div>
            </ContentBox>

        </div>
    );
});

export default AdministratorInformation;