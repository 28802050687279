import { Input, Typography } from 'antd';
import { Field } from 'formik';
import React from 'react';
import { runes } from 'runes2';
import './input.scss';

//@ts-ignore
interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    placeholder?: string;
    maxLength?: number;
    disabled?: boolean;
    value?: string;
    showMaxLength?: boolean;
    name?: string; // Tornando o name obrigatório
    size?: string;
    hidden?: boolean;
    prefixIcon?: React.ReactNode;
    validateTouched?: boolean;
}

const InputComponent: React.FC<InputProps> = ({ label, hidden, showMaxLength, value, prefixIcon, maxLength, name, size, disabled, placeholder, validateTouched = true, ...props }) => (
    <div className="flex flex-col w-full input-container">
        {label && <Typography.Title level={5}>{label}</Typography.Title>}
        <Field name={name}>
            {({ field, meta }: any) => {
                return (
                    <>
                        <Input
                            {...field}
                            maxLength={maxLength}
                            size={size ? size : "large"}
                            disabled={disabled}
                            value={value ? value : field.value}
                            placeholder={placeholder}
                            count={showMaxLength ? {
                                show: true,
                                max: maxLength,
                                strategy: (txt) => runes(txt).length,
                            } : null}
                            status={(validateTouched && meta.touched || !validateTouched) && (meta.error)? 'error' : ''}
                            prefix={prefixIcon}
                        />
                        {(validateTouched && meta.touched || !validateTouched) && meta.error && (
                            <Typography.Text type="danger">{(meta.error)}</Typography.Text>
                        )}
                    </>
                );
            }}
        </Field>
    </div>
);

export default InputComponent;
