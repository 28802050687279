import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import { message } from 'antd';
import { AllocationModel } from '../types/AllocationModel';
import AllocationService from '../services/AllocationService';
import { formatDateAndTime } from '../utils/dateFunctions';
import { formatContainerList } from '../utils/functions';
import { transformToValueLabel } from '../utils/selectFunction';

class AllocationStore {
    loading: boolean = false;
    sort?: string = '-date_created';
    allocation?: any;
    allocationList: AllocationModel[] = [];
    statusList: any[] = [];
    reverses: any[] = [];
    statuses: { value: string, label: string }[] = [];
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    pageSize: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        pageSize: DEFAULT_PAGE_SIZE,
        page: 1,
    }


    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.allocation = undefined;
    }

    async getList(params: any) {
        this.loading = true;
        const response = await AllocationService.getList(params);

        const urlParams = new URLSearchParams(params);
        const pageSize = Number(urlParams.get('pageSize') || DEFAULT_PAGE_SIZE);

        runInAction(() => {
            if (!response.error) {
                this.allocationList = response.data.content.map((item: any) => ({
                    ...item,
                    dateAllocated: item.dateAllocated ? formatDateAndTime((item.dateAllocated)) : null,
                    dateCreated: item.dateCreated ? formatDateAndTime((item.dateCreated)) : null,
                    dateEmailNotification: item.dateEmailNotification ? formatDateAndTime((item.dateEmailNotification)) : null,
                    dateMessageNotification: item.dateMessageNotification ? formatDateAndTime((item.dateMessageNotification)) : null,
                    dateRetrieved: item.dateRetrieved ? formatDateAndTime((item.dateRetrieved)) : null,
                    ports: formatContainerList(item.containerAllocations)
                }));

                this.totalPages = response.data.totalPages;
                this.page = response.data.page;
                this.totalElements = response.data.totalElements;
                this.pageSize = pageSize;
            }
            this.loading = false;
        });

        return response;
    }

    async getAllocationByUuid(uuid: string) {
        this.loading = true;
        const response = await AllocationService.getAllocationByUuid(uuid);
        this.loading = false;

        if (!response.error) {
            this.allocation = {
                ...response.data,
                dateAllocated: response.data?.dateAllocated ? formatDateAndTime(response.data?.dateAllocated) : null,
                dateCreated: response.data?.dateCreated ? formatDateAndTime(response.data?.dateCreated) : null,
                dateEmailNotification: response.data?.dateEmailNotification ? formatDateAndTime(response.data?.dateEmailNotification) : null,
                dateMessageNotification: response.data?.dateMessageNotification ? formatDateAndTime(response.data?.dateMessageNotification) : null,
                dateRetrieved: response.data?.dateRetrieved ? formatDateAndTime(response.data?.dateRetrieved) : null,
                ports: response.data?.containerAllocations ? formatContainerList(response.data?.containerAllocations) : [],
            };
        } else {
            message.error(response.error);
        }
        return response.data;
    }

    async getAllocationByCodeAndLockerUuid(code: string, uuidLocker: string) {
        this.loading = true;
        const response = await AllocationService.getAllocationByCodeAndLockerUuid(
            code,
            uuidLocker
        );
        this.loading = false;

        runInAction(() => {
            if (!response.error) {
                this.allocation = response.data;
            } else {
                message.error(response.error);
            }
        });
        return response;
    }

    async getLockerReverseList(uuidLocker: string) {
        this.loading = true;
        const response = await AllocationService.getLockerReverseList(uuidLocker);
        this.loading = false;

        runInAction(() => {
            if (!response.error) {
                this.reverses = response.data.content;
            } else {
                message.error(response.error);
            }
        });
    }


    async getListStatus() {
        const response = await AllocationService.getListStatus();

        runInAction(() => {
            if (!response.error) {
                this.statusList = transformToValueLabel(response.data, 'code', 'name');
            } else {
                message.error(response.error);
            }
        });
    }

    async export(params: any) {
        this.loading = true;
        const response = await AllocationService.export(params);
        this.loading = false;
        runInAction(() => {
            if (response.error) {
                message.error(response.error);
                return undefined;
            }
        });
        return response.data;
    }

    async createAllocation(allocation: any) {
        this.loading = true;

        const response = await AllocationService.createAllocation(
            allocation
        );

        runInAction(() => {
            if (!response.error) {
                message.success('Alocação cadastrado com sucesso!');
            } else {
                message.error(response.error);
                this.allocation = allocation;
            }
        });
        this.loading = false;
        return response;
    }


    async updateObservation(uuid: string, observations: string) {
        this.loading = true;

        const containerIndex =
        this.allocation.containerAllocations.length > 0 ? this.allocation.containerAllocations.findIndex(
          (item: any) => item.uuid === uuid
        ) : -1;

        if (containerIndex !== -1) {
            this.allocation.containerAllocations[containerIndex].observations = observations;
        }
        this.loading = false;
    }

    async updateAllocation(allocation: any, uuid: string) {
        this.loading = true;

        const response = await AllocationService.updateAllocation(
            uuid,
            allocation
        );
        this.loading = false;

        runInAction(() => {
            if (!response.error) {
                message.success('Alocação atualizado com sucesso!');
            } else {
                message.error(response.error);
            }
        });

        return response;
    }

    async sendMensage(uuid: string) {
        const response = await AllocationService.sendMessageNewAllocation(uuid);

        runInAction(() => {
            if (!response.error) {
                message.success('QR code Mensagem enviada com sucesso!');
            } else {
                message.error(response.error);
            }
        });

        return response;
    }


    async sendEmail(uuid: string) {
        const response = await AllocationService.sendEmailNewAllocation(uuid);

        runInAction(() => {
            if (!response.error) {
                message.success('E-mail enviada com sucesso!');
            } else {
                message.error(response.error);
            }
        });

        return response;
    }
}

const allocationStore = new AllocationStore();
export default allocationStore;