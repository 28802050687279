import * as Yup from 'yup';

// Definindo o esquema de validação com Yup
export const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nome obrigatório!"),
    street: Yup.string().required("Rua obrigatória"),
    district: Yup.string().required("Bairro obrigatório"),
    number: Yup.string().required("Número obrigatório"),
    zipCode: Yup.string().required("CEP obrigatório"),
    city: Yup.string().required("Cidade obrigatório"),
    state: Yup.object().shape({
        value: Yup.string().required("Estado obrigatório"),
        label: Yup.string(),
      }),
    parent: Yup.object().shape({
        value: Yup.string().required("Organização pai obrigatória!"),
        label: Yup.string(),
      }),
});