import { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps } from 'antd';
import { Header } from 'antd/es/layout/layout';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import DrawerMenu from '../components/organisms/DrawerMenu';
import MenuComponent from '../components/organisms/MenuComponent';
import { AuthProvider } from '../components/protected/ProviderAuth';
import { COOKIE_NAMES } from '../config/cookieConstants';
import authStore from '../stores/authStore';
import './styles.scss';
import UserHeaderComponent from '../components/molecules/UserHeaderComponent';

interface MainLayoutContentProps {
    setIsDarkMode: () => void;
}

const MainLayoutContent: React.FC<MainLayoutContentProps> = ({ setIsDarkMode }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const email = Cookies.get(COOKIE_NAMES.EMAIL);

    const items: MenuProps['items'] = [
        {
            key: '2',
            label: (
                <p onClick={() => authStore.logout()}>
                    Desconectar
                </p>
            ),
        },
    ];

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    return (
        <>
            <div className="h-screen overflow-hidden flex">
                <div className="custom-menu hidden lg:block">
                    <MenuComponent collapsed={collapsed} />
                </div>

                <div className={`inline-block w-full lg:w-3/4 align-top`} style={{
                    width: '100%',
                    ...(window.innerWidth >= 768 && { width: 'calc(100%)' })
                }}>
                    {/* Header Web */}
                    <div className="hidden lg:block">
                        <Header className="header border-b dark:bg-midnight dark:border-0 ">
                            <div className="flex gap-3 items-center lg:block">
                                <div>
                                    {collapsed ? <MenuUnfoldOutlined className="dark:text-white" onClick={toggleCollapsed} /> : <MenuFoldOutlined className="dark:text-white" onClick={toggleCollapsed} />}
                                </div>
                            </div>

                            <div className="flex gap-6 flex-row">
                                <Dropdown className="flex items-center cursor-pointer" menu={{ items }} placement="bottomRight" arrow>
                                    <div className="flex gap-2" onClick={(e) => e.preventDefault()}>
                                        <UserOutlined style={{fontSize: "22px"}} />
                                        <div><p className="dark:text-white">{email}</p></div>
                                    </div>
                                </Dropdown>
                            </div>
                        </Header>
                    </div>

                    {/* Header Mobile */}
                    <div className="flex items-center lg:hidden header-mobile border-b">
                        <div onClick={() => setOpenMenu(true)}>
                            <UserHeaderComponent />
                        </div>
                    </div>

                    <div className="box dark:bg-midnight">
                        <Outlet />
                    </div>
                </div>
            </div>

            <DrawerMenu setOpenMenu={setOpenMenu} openMenu={openMenu} />
        </>
    );
};

interface MainLayoutProps {
    setIsDarkMode: () => void;
}

const MainLayout: React.FC<MainLayoutProps> = ({ setIsDarkMode }) => {
    return (
        <AuthProvider>
            <MainLayoutContent setIsDarkMode={setIsDarkMode} />
        </AuthProvider>
    );
};

export default MainLayout;