import * as Yup from 'yup';

// Definindo o esquema de validação com Yup
export const validationSchema = Yup.object().shape({
    locker: Yup.object().shape({
        value: Yup.string().required("Locker obrigatório!"),
        label: Yup.string(),
    }),
    customer: Yup.object().shape({
        value: Yup.string().required("Cliente obrigatório!"),
        label: Yup.string(),
    }),
});

export const editValidationSchema = Yup.object().shape({
    status: Yup.object().shape({
        value: Yup.string().required("Status obrigatório!"),
        label: Yup.string(),
    }),
    customer: Yup.object().shape({
        value: Yup.string().required("Cliente obrigatório!"),
        label: Yup.string(),
    }),
});