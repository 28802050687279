import React, { useState } from 'react';
import { EyeOutlined, MoreOutlined } from '@ant-design/icons';
import { Divider, Dropdown, Tag } from 'antd';
import { DeleteOutlined, EditOutlined, FileImageOutlined } from '@ant-design/icons';
import DrawerDelete from '../../DrawerDelete';
import { getStatusOrderColor } from '../../../../utils/statusColorMapper';

interface ListViewItem {
    name: string; // Nome customizado a ser exibido
    key: string;
    type?: string;  // Chave no objeto listItem
}

interface BoxReportProps {
    title?: string;
    subTitle?: string;
    headerIcon?: React.ReactNode;
    itemData?: any;
    viewItems?: ListViewItem[];
    editRouter?: (item: any) => void;
    deleteItem?: (item: any) => void;
    imageItem?: (item: any) => void;
}

const getNestedValue = (obj: any, path: string): any => {
    return path.split('.').reduce((acc, key) => acc?.[key], obj);
};


const DetailCardComponent: React.FC<BoxReportProps> = ({ itemData, editRouter, imageItem, deleteItem, title, subTitle, viewItems }) => {
    const [openDeleteDranwer, setOpenDeleteDrawer] = useState(false);
    const [visible, setVisible] = useState(false);

    const items: any = [
        editRouter && {
            key: '1',
            label: 'Visualizar',
            icon: <EyeOutlined />,
            onClick: () => {
                editRouter(itemData);
                setVisible(false); // Fecha o dropdown ao clicar
            },
        },
        deleteItem && {
            key: '2',
            label: 'Excluir',
            icon: <DeleteOutlined />,
            onClick: () => {
                setOpenDeleteDrawer(true);
                setVisible(false);
            },
        },
        imageItem && {
            key: '3',
            label: 'Imagem',
            icon: <FileImageOutlined />,
            onClick: () => {
                imageItem(itemData);
                setVisible(false);
            },
        },
    ].filter(Boolean);

    return (
        <>
            <div className="p-4 bg-white border border-gray-200 rounded">
                <div className="flex justify-between">

                    <div className="flex gap-3 truncate">
                        <div className="flex flex-col">
                            <p className="text-base font-bold">{title}</p>
                            <p className="text-sm text-gray-500 ">{subTitle}</p>
                        </div>
                    </div>

                    <div className="cursor-pointer">
                        {(editRouter || deleteItem || imageItem) && (<Dropdown
                            menu={{ items }}
                            visible={visible}
                            onVisibleChange={setVisible}
                            trigger={['click']}>
                            <MoreOutlined />
                        </Dropdown>)}
                    </div>
                </div>

                {viewItems && viewItems.length > 0 &&
                    <div>
                        <Divider className="my-3" />

                        <div className="flex flex-col gap-1">
                            {viewItems?.map(({ name, key, type }) => {
                                return (
                                    <div key={key} className="flex justify-between text-sm gap-4">
                                        <p>{name}:</p>

                                        {type === 'tag' ?
                                            <Tag className="mr-0" color={getStatusOrderColor(getNestedValue(itemData, key) || 'N/A')}>{getNestedValue(itemData, key) || 'N/A'}</Tag>
                                            : <p className="text-gray-500 text-end">{getNestedValue(itemData, key) || 'N/A'}</p>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                }
            </div >

            <DrawerDelete setOpenDeleteDrawer={setOpenDeleteDrawer} openDeleteDranwer={openDeleteDranwer} deleteItem={deleteItem || (() => { })} itemData={itemData} />
        </>
    )
};

export default DetailCardComponent;