import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import CustomerService from '../services/CustomerService';
import { message } from 'antd';
import { CustomerModel } from '../types/CustomerModel';
import { CountryModel } from '../types/CountryModel';
import { transformToValueLabel } from '../utils/selectFunction';

class CustomerStore {
    loading: boolean = false;
    loadingCustomer: boolean = false;
    sort?: string = '-date_created';
    customerList: CustomerModel[] = [];
    customer: CustomerModel | undefined;
    customerSelect: any[] = [];
    countriesList: any[] = [];
    selectedCountry?: CountryModel = undefined;
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    pageSize: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        pageSize: DEFAULT_PAGE_SIZE,
        page: 1,
    }


    reset() {
        this.customer = undefined;
        this.customerList = [];
        this.selectedCountry = undefined;
    }

    constructor() {
        makeAutoObservable(this);
    }


    setSelectedCountry(value?: CountryModel) {
        this.selectedCountry = value;
    }

    async getList(params: any) {
        this.loading = true;
        const response = await CustomerService.getList(params);

        const urlParams = new URLSearchParams(params);
        const pageSize = Number(urlParams.get('pageSize') || DEFAULT_PAGE_SIZE);

        runInAction(() => {
            if (!response.error) {
                this.customerList = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.page;
                this.totalElements = response.data.totalElements;
                this.pageSize = pageSize;
            }
            this.loading = false;
        });

        return response;
    }


    async getCustomerAsyncSelect(name: any) {
        const filter = `fullName__icontains=${name}`;
        const sort = 'full_name'
        const response = await CustomerService.getList({ filter, sort });

        runInAction(() => {
            if (!response.error) {
                this.customerSelect = transformToValueLabel(response.data.content, 'uuid', 'fullName');
            } else {
                message.error(response.error);
            }
            this.loading = false;
        });
        return response;
    }


    async getCountries() {
        try {
            this.loading = true;
            const response = await CustomerService.getCountries();

            runInAction(() => {
                if (!response.error) {
                    this.countriesList = response.data.map((item: any) => ({
                        value: item.uuid,
                        label: item.phoneDigit,
                        option: item as CountryModel,
                    }));
                }
                this.loading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.loading = false;
            });
            console.error("Failed to fetch countries", error);
        }
    }



    async createCustomer(customer: any) {
        this.loading = true;
        const response = await CustomerService.createCustomer(customer);

        runInAction(() => {
            if (response.error) {
                message.error(response?.error);
            } else {
                message.success('Cliente cadastrado com sucesso!');
            }
            this.loading = false;
        });

        return response;
    }

    async editCustomer(customer: CustomerModel, uuid: string | undefined) {
        this.loading = true;
        const response = await CustomerService.editCustomer(customer, uuid);

        runInAction(() => {
            if (response.error) {
                message.error(response?.error);
            } else {
                message.success('Cliente editado com sucesso!');
            }
            this.loading = false;
        });

        return response;
    }

    async getCustomerByUuid(uuid: string) {
        this.loadingCustomer = true;
        const response = await CustomerService.getCustomerByUuid(uuid);

        runInAction(() => {
            if (response.error) {
                message.error('Erro ao buscar Cliente');
                return;
            }
            this.customer = response.data;
        });

        this.loadingCustomer = false;
        return response;
    }

    async deleteCustomer(uuid: string) {
        this.loading = true;
        const response = await CustomerService.deleteCustomer(uuid);
        this.loading = false;

        runInAction(() => {
            if (response.error) {
                message.error(response.error);
                return;
            } else {
                message.success("Cliente deletado com sucesso");
                this.getList(this.defaultparams);
            }
        });
        return response;
    }

    async deleteCustomerAll(uuid: string) {
        this.loading = true;
        const response = await CustomerService.deleteCustomer(uuid);
        this.loading = false;

        runInAction(() => {
            if (!response.error) {
                this.customer = undefined;
            }
        });

        return response;
    }

    async export(params: any) {
        this.loading = true;
        const response = await CustomerService.export(params);
        this.loading = false;
        runInAction(() => {
            if (response.error) {
                message.error(response.error);
                return undefined;
            }
        });
        return response.data;
    }

    async import(data: any) {
        this.loading = true;
        const response = await CustomerService.import(data);
        this.loading = false;
        if (!response.error) {
            message.success("Dados importados com sucesso!");
            this.getList(this.defaultparams);
        } else {
            message.error(response.error);
        }
        return response;
    }


}

const customerStore = new CustomerStore();
export default customerStore;