import { FileImageOutlined, MoreOutlined, EditOutlined } from '@ant-design/icons';
import { Divider, Space, Table, TableColumnsType, Tag, Input, Form, Alert, Tooltip, message } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import allocationStore from '../../../../stores/allocationStore';
import { getStatusColor } from '../../../../utils/statusColorMapper';
import ActionButton from '../../../atoms/ButtonComponent';
import ContentBox from '../../../molecules/ContentBox';
import TableCardComponent from '../../../molecules/TableCardComponent';
import DrawerBottom from '../../../organisms/DrawerButton';
import ModalComponent from '../../../organisms/ModalComponent';
import ModalImageLocker from '../../../organisms/ModalImageLocker';

interface DescriptionItemProps {
    title: string;
    content?: string;
    className?: string;
}

const DescriptionItem = ({ title, content, className }: DescriptionItemProps) => (
    <div title={content} className={`${className} flex gap-2 pb-2`}>
        <p className="text-sm inline-block text-gray-500 font-normal">{title}:</p>
        <p className="text-sm text-zinc-700 font-medium">{content}</p>
    </div>
);

interface DataType {
    customer: any;
    organization: any;
    email: any;
    fullName: any;
    uuid: string;
    key: React.Key;
    observations?: string;
    container?: {
        name: string;
        containerType: {
            name: string;
        };
        containerMeasure: {
            name: string;
        };
    };
    inLocker: boolean;
}

const AlocationDetails: React.FC = observer(() => {
    const { allocation, loading } = allocationStore;
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [itemAlocation, setItemAlocation] = useState<any>();
    const [openModalQrCode, setOpenModalQrCode] = useState<boolean>(false);
    const [openModalMensageQrCode, setOpenModalMensageQrCode] = useState<boolean>(false);
    const [openModalEmailQrCode, setOpenModalEmailQrCode] = useState<boolean>(false);
    const [bottomOptions, openBottomOptions] = useState<any>(false);
    const [openModalObservation, setOpenModalObservation] = useState<boolean>(false);
    const [currentObservation, setCurrentObservation] = useState<string>('');
    const [currentItemUuid, setCurrentItemUuid] = useState<string>('');
    const [form] = Form.useForm();

    const handleEditObservation = (item: any) => {
        setCurrentObservation(item.observations || '');
        setCurrentItemUuid(item.uuid);
        form.setFieldsValue({ observation: item.observations || '' });
        setOpenModalObservation(true);
    };

    const handleSaveObservation = async () => {
        allocationStore.updateObservation(currentItemUuid, currentObservation)
        setOpenModalObservation(false);
    };

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Cód. Porta',
            dataIndex: 'container',
            key: 'code',
            render: (item) => {
                return (
                    <>{item && <p>{item?.name}</p>}</>
                );
            },
        },
        {
            title: 'Tipo Porta',
            dataIndex: 'container',
            key: 'type',
            render: (item) => {
                return (
                    <>{item && <p>{item?.containerType.name}</p>}</>
                );
            },
        },
        {
            title: 'Cód. Tamanho',
            dataIndex: 'container',
            key: 'codeSize',
            render: (item) => {
                return (
                    <>{item && <p>{item?.containerMeasure.name}</p>}</>
                );
            },
        },
        {
            title: 'Observações',
            dataIndex: 'observations',
            key: 'observation',
            render: (text, record) => {
                return (
                    <div className="flex items-center justify-between group">
                        <span className="flex-1">{text || 'Sem observações'}</span>
                        <EditOutlined
                            className="text-blue-600 cursor-pointer ml-2 hover:text-blue-800 opacity-0 group-hover:opacity-100 transition-opacity"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleEditObservation(record);
                            }}
                        />
                    </div>
                );
            },
        },
        {
            title: 'Dentro do Locker',
            dataIndex: 'inLocker',
            key: 'inLocker',
            sorter: true,
            render: (inLocker) => {
                let text = inLocker ? 'Sim' : 'Não';
                return (
                    <Tag color={getStatusColor(text)}><p>{text}</p></Tag>
                );
            },
        },
        {
            title: 'Imagem',
            dataIndex: 'image',
            key: 'image',
            align: 'center',
            width: 80,
            render: (text, record) => (
                <Space onClick={() => {
                    setOpenModal(true);
                    setItemAlocation(record)
                }} className="cursor-pointer">
                    <div><FileImageOutlined /></div>
                </Space>
            ),
        },
    ];


    const openModalImage = (item: any) => {
        setOpenModal(true);
        setItemAlocation(item)
    }


    const returnAllocations = allocation && allocation?.containerAllocations.map((item: any) => ({
        ...item,
        inLocker: item.inLocker === true ? 'Sim' : 'Não'
    }));


    const sendQrCodeMensage = () => {
        allocationStore.sendMensage(allocation.uuid)
    }

    const sendQrCodeEmail = () => {
        allocationStore.sendEmail(allocation.uuid)
    }

    const isStatusReady = allocation?.status?.code === 'READY';

    const showMessage = () => {
        message.info("Somente é possível reenviar notificações quando status for \"Pronto para retirada\"")
    }

    return (
        <>
            <ContentBox className="mb-4 p-4 lg:p-6" borderCustom='rounded-b-lg' title="Detalhes da Alocação" description='Informações da Alocação' actions={
                <>
                    <div className="gap-2 hidden lg:flex">
                        <ActionButton actionType="export" onClick={() => setOpenModalQrCode(true)}>Ver QR Code</ActionButton>
                        {!isStatusReady ?
                            <>
                                <Tooltip placement="top" title={"Somente é possível reenviar notificações quando status for \"Pronto para retirada\""}>
                                    <ActionButton actionType="export" disabled onClick={() => setOpenModalMensageQrCode(true)}>Reenviar mensagem - QrCode</ActionButton>
                                </Tooltip>
                                <Tooltip placement="top" title={"Somente é possível reenviar notificações quando status for \"Pronto para retirada\""}>
                                    <ActionButton actionType="export" disabled onClick={() => setOpenModalEmailQrCode(true)}>Reenviar e-mail - QrCode</ActionButton>
                                </Tooltip>
                            </>
                            :
                            <>
                                <ActionButton actionType="export" onClick={() => setOpenModalMensageQrCode(true)}>Reenviar mensagem - QrCode</ActionButton>
                                <ActionButton actionType="export" onClick={() => setOpenModalEmailQrCode(true)}>Reenviar e-mail - QrCode</ActionButton>
                            </>
                        }
                    </div>

                    <div className="block lg:hidden">
                        <div style={{ background: '#FFFAF2' }} onClick={() => openBottomOptions(true)} className="bg-slate-50 flex min-w-10 min-h-10 justify-center rounded-full">
                            <MoreOutlined />
                        </div>
                    </div>
                </>
            }>
                <Divider className="mt-2" />

                <div className="grid grid-cols-1 gal-2 lg:grid-cols-2 lg:flex-row lg:gap-8">
                    <DescriptionItem title="Código" content={allocation?.code} />
                    <DescriptionItem title="Código Externo" content={allocation?.externalCode} />
                </div>

                <div className="grid grid-cols-1 gal-2 lg:grid-cols-2 lg:flex-row lg:gap-8">
                    <DescriptionItem title="Cliente" content={allocation?.customer.fullName} />
                    <DescriptionItem title="Status" content={allocation?.status.name} />
                </div>

                <div className="grid grid-cols-1 gal-2 lg:grid-cols-2 lg:flex-row lg:gap-8">
                    <DescriptionItem title="Dt. Criação" content={allocation?.dateCreated} />
                    <DescriptionItem title="Dt. Alocação" content={allocation?.dateAllocated} />
                </div>

                <div className="grid grid-cols-1 gal-2 lg:grid-cols-2 lg:flex-row lg:gap-8">
                    <DescriptionItem title="Dt. Retirada" content={allocation?.dateRetrieved} />
                    <DescriptionItem title="Dt. Envio Mensagem" content={allocation?.dateEmailNotification} />
                </div>

                <div className="grid grid-cols-1 gal-2 lg:grid-cols-2 lg:flex-row lg:gap-8">
                    <DescriptionItem title="Dt. Envio E-mail" content={allocation?.dateMessageNotification} />
                    <DescriptionItem title="Organização" content={allocation?.organization?.fullName} />
                </div>

                <div className="hidden lg:block">
                    <Divider />

                    <Table
                        className="table-custom"
                        columns={columns}
                        dataSource={allocation && allocation?.containerAllocations && allocation?.containerAllocations.map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        loading={loading}
                        size="middle"
                        bordered={false}
                        pagination={false}
                    />
                </div>
            </ContentBox>

            <TableCardComponent
                itemData={returnAllocations && returnAllocations}
                titlePage="Alocações"
                subTitlePage="Informações da Alocação"
                viewItems={[{ name: 'Cód. Tamanho', key: 'container.containerMeasure.name' }, { name: 'Observações', key: 'observations' }, { name: 'Dentro do Locker', key: 'inLocker', type: "tag" }]}
                titleKey="container.name"
                subTitleKey="container.containerType.name"
                inputName="name__icontains"
                placeholder="Pesquisar Alocações"
                loading={loading}
                imageItem={openModalImage}
                editRouter={handleEditObservation}
            />

            <ModalImageLocker
                itemAlocation={itemAlocation}
                title="Imagem Alocação"
                handleOk={() => setOpenModal(false)}
                isModalOpen={openModal}
                handleCancel={() => setOpenModal(false)}
            />

            <ModalComponent title="QR Code"
                content={<div className="flex justify-center"><img src={allocation?.qrCode} alt='QR Code' /></div>}
                isModalOpen={openModalQrCode}
                width={360}
                handleOk={() => setOpenModalQrCode(false)}
                handleCancel={() => setOpenModalQrCode(false)} />

            <ModalComponent title="Reenviar Mensagem QrCode"
                content={<div className="flex justify-center"><p>{`Você deseja reenviar a mensagem para `}<b>{`${allocation?.customer?.fullName} - ${allocation?.customer?.phone} ?`}</b></p></div>}
                isModalOpen={openModalMensageQrCode}
                handleOk={() => sendQrCodeMensage()}
                handleCancel={() => setOpenModalMensageQrCode(false)} />

            <ModalComponent title="Reenviar E-mail QrCode"
                content={<div className="flex justify-center"><p>{`Você deseja reenviar o e-mail para `}<b>{`${allocation?.customer?.fullName} - ${allocation?.customer?.email} ?`}</b></p></div>}
                isModalOpen={openModalEmailQrCode}
                handleOk={() => sendQrCodeEmail()}
                handleCancel={() => setOpenModalEmailQrCode(false)} />

            <ModalComponent
                title="Editar Observação"
                content={
                    <div className="w-full px-4">
                        <Form
                            form={form}
                            layout="vertical"
                            initialValues={{ observation: currentObservation }}
                        >
                            <Alert message="As alterações feitas nesse campo serão confirmadas ao concluir a atualização de alocação." type="info" />
                            <br />
                            <Form.Item
                                name="observation"
                                rules={[{ required: true, message: 'Por favor, insira uma observação' }]}
                            >
                                <Input.TextArea
                                    rows={4}
                                    placeholder="Digite sua observação aqui"
                                    onChange={(e) => setCurrentObservation(e.target.value)}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                }
                isModalOpen={openModalObservation}
                handleOk={handleSaveObservation}
                handleCancel={() => {
                    setOpenModalObservation(false);
                    form.resetFields();
                }}
            />

            <DrawerBottom openMenu={bottomOptions} setOpenMenu={openBottomOptions}>
                <p onClick={() => setOpenModalQrCode(true)} className="h-8">Ver QR Code</p>
                <p onClick={() => isStatusReady ? setOpenModalMensageQrCode(true) : showMessage()} className="h-8">Reenviar QrCode</p>
                <p onClick={() => isStatusReady ? setOpenModalEmailQrCode(true) : showMessage()} className="h-8">Reenviar e-mail - QrCode</p>
            </DrawerBottom>
        </>
    );
});

export default AlocationDetails;