import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import configStore from '../../../stores/configStore';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import ColorPickerComponent from '../../atoms/ColorPickerComponent';
import InputComponet from '../../atoms/InputComponet';
import ImageUploadComponent from '../../atoms/UploadComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from '../../molecules/Permission';
import { PermissionCompanyFeatures } from '../../../permissions/PermissionCompanyFeatures';

interface LogoType {
    name: string;
    preview: string;
    thumbUrl: string;
}

const breadCrumb = [
    {
        title: <p>Cadastro de Configuração</p>,
    },
]

const CreateConfig: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { loading, companyProps } = configStore;

    useEffect(() => {
        const getInformation = async () => {
            await configStore.getCompany();
            document.title = 'Configurações'
        };

        getInformation();

        return () => {
            configStore.reset();
        };
    }, [uuid]);

    const initialValues: any = {
        onScreenName: companyProps?.onScreenName || '',
        primaryColor: companyProps?.primaryColor || '',
        secondaryColor: companyProps?.secondaryColor || '',
        logo: [
            {
                name: 'logo',
                preview: companyProps?.logo, // URL da imagem default
                thumbUrl: companyProps?.logo,
            },
        ],
        secondaryLogo: [
            {
                name: 'secondaryLogo',
                preview: companyProps?.secondaryLogo, // URL da imagem default
                thumbUrl: companyProps?.secondaryLogo,
            },
        ],
    };

    // Faz o filtro da tabela
    const handleSubmit = async (values: {
        onScreenName: string;
        primaryColor: string;
        secondaryColor: string;
        logo: LogoType[];
        secondaryLogo: LogoType[];
    }) => {
        let response;

        const createProps = {
            ...values,
        }

        response = await configStore.editCompany(createProps);
    };


    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} />

            {loading &&
                <ContentBox className="mb-4 p-4 lg:p-6" title="Cadastro de Configurações" description='Tela para cadastro de Configuração'>
                    <LoadingComponent />
                </ContentBox>
            }
            <Permission permissionKeys={[PermissionCompanyFeatures.VIEW]} containerType>
                {!loading &&
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    //validationSchema={validationSchema}
                    >
                        <Form>
                            <ContentBox className="mb-4 p-4 lg:p-6" title="Cadastro de Configurações" description='Tela para cadastro de configurações da aplicação'>
                                <div className="flex w-full gap-4 pb-4 flex-col lg:flex-row">
                                    <InputComponet label="Nome apresentado em tela*" name="onScreenName" id="onScreenName" />
                                </div>

                                <div className="flex flex-col lg:grid grid-cols-12 gap-4">
                                    <div className="col-span-6">
                                        <ColorPickerComponent label="Cor Primária" name="primaryColor" id="primaryColor" />
                                    </div>

                                    <div className="col-span-6" >
                                        <ColorPickerComponent label="Cor Secundária" name="secondaryColor" id="secondaryColor" />
                                    </div>
                                </div>
                            </ContentBox>

                            <ContentBox className="mb-4 p-4 lg:p-6" title="Cadastro de Imagens" description='Cadastro de Imagens da Aplicação'>
                                <div className="flex w-full gap-4 pb-4 flex-col lg:flex-row">
                                    <ImageUploadComponent maxCount={1} name="logo" label="Banner Vitrine" />
                                    <ImageUploadComponent maxCount={1} name="secondaryLogo" label="Banner Vitrine" />
                                </div>
                            </ContentBox>

                            <div className="flex justify-end">
                                <Permission permissionKeys={[PermissionCompanyFeatures.PUT]} buttonType>
                                    <ActionButton htmlType="submit" actionType="primary">{'Atualizar'}</ActionButton>
                                </Permission>
                            </div>
                        </Form>
                    </Formik >
                }
            </Permission>
        </>
    );
});

export default CreateConfig;